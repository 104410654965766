<template>
  <div class="container">
    <div class="static-page connect-page">
      <div class="how-to-connect">
        <div class="left">
          <h1>Как отельеру зарегистрировать
            свой объект</h1>
          <p>Аламат — туроператор и агрегатор по бронированию. Наш сайт каждый день посещают более 15 тысяч
            пользователей. В апреле 2022 года мы получили более 800 тысяч запросов на поиск варианта проживания.</p>
          <p>Разместите свой отель, турбазу, хостел или другой объект для получения бронирований от пользователей.
            Регистрация займёт не более 5 минут.
          </p>
          <button class="btn btn-info connect" @click="connectHotel">Подключить отель</button>
        </div>
        <div class="right" v-if="this.$store.getters.width > 992">
          <div>
            <img src="@/assets/images/static-page/connect-hotel/iphone-mac.png">
          </div>
        </div>
      </div>
      <div class="why-me">
        <div class="why-me-item">
          <img src="@/assets/images/static-page/connect-hotel/support.png">
          <label>Круглосуточная поддержка</label>
        </div>
        <div class="why-me-item">
          <img src="@/assets/images/static-page/connect-hotel/procent.png">
          <label>Коммисия 15%</label>
        </div>
        <div class="why-me-item">
          <img src="@/assets/images/static-page/connect-hotel/graphics.png">
          <label>Еженедельные выплаты</label>
        </div>
        <div class="why-me-item">
          <img src="@/assets/images/static-page/connect-hotel/free.png">
          <label>Бесплатная регистрация</label>
        </div>
      </div>
      <h1 class="title">Требования для подключения к сервису</h1>
      <div class="request-connect">
        <div class="request-connect-item">
          <img src="@/assets/images/static-page/connect-hotel/travel.png">
          <label>Travelline</label>
          <p>К Аламат можно подключить объекты, которые работают с движком бронирования Travelline.</p>
        </div>
        <div class="request-connect-item">
          <img src="@/assets/images/static-page/connect-hotel/type.png">
          <label>Юридические лица или ИП</label>
          <p>Подключение возможно только для юридических лиц или ИП.</p>
        </div>
        <div class="request-connect-item">
          <img src="@/assets/images/static-page/connect-hotel/only.png">
          <label>Только отели и гостиницы</label>
          <p>Недоступно подключение апартаментов и вариантов посуточной аренды.</p>
        </div>
      </div>
      <h1 class="title">Как подключить Аламат</h1>
      <div class="select-engine" id="connect-engine">
        <label>Выберите свой движок бронирования</label>
        <div class="engines">
          <div class="engine" :class="[engines.travel ? 'active': '']" @click="switchEngine('travel')">TravelLine</div>
          <div class="engine" :class="[engines.bnovo ? 'active': '']" @click="switchEngine('bnovo')">Bnovo</div>
          <div class="engine" :class="[engines.alamat ? 'active': '']" @click="switchEngine('alamat')">Alamat</div>
          <div class="engine" :class="[engines.another ? 'active': '']" @click="switchEngine('another')">Другой</div>
        </div>
      </div>
      <div class="select-tutorial" v-if="engines.travel">
        <div class="another-hotel">
          <p>Поделитесь, каким движком бронирования вы пользуетесь, и расскажите немного о вашем отеле. Если мы получим
            этот движок, мы с вами свяжемся.</p>
          <form @submit.prevent="sendRequest">
            <div class="form-group">
              <input type="text" placeholder="ИНН" v-model="data.inn" @keyup="helpHint">
              <transition name="fade">
                <div class="search-result" v-if="showCompany" style="top: 45px;">
                  <div class="result-list">
                    <template v-if="hint.length > 0">
                      <div class="list-label">Выберите компанию</div>
                      <div class="result-list-item" v-for="(company, index) in hint" :key="index"
                           @click="selectCompany(company)">
                        {{ company.value }} - <span class="active" v-if="company.data.state.status == 'ACTIVE'">АКТИВ</span> <span class="liquid" v-else>ЛИКВИД</span>
                      </div>
                    </template>
                    <template v-else>
                      <div class="list-label">Ничего не найдено</div>
                    </template>
                  </div>
                </div>
              </transition>
            </div>
            <div class="form-group">
              <input type="text" placeholder="Сайт (если есть)" v-model="data.site">
            </div>
            <div class="form-group">
              <input type="text" placeholder="Название отеля" v-model="data.hotel">
            </div>
            <div class="form-group">
              <input type="text" placeholder="Контактное лицо" v-model="data.contact">
            </div>
            <div class="form-group">
              <input type="text" placeholder="Почта" v-model="data.email" :class="validate.email ? 'validate': ''" @keyup="clearError">
            </div>
            <div class="form-group">
              <masked-input type="text" class="masked" mask="\+\7-111-111-11-11" placeholder="Телефон" v-model="data.phone"/>
            </div>
            <div class="form-group">
              <input type="text" placeholder="Полное наименование" v-model="data.fullName">
            </div>
            <div class="form-group">
              <input type="text" placeholder="Юридический адрес" v-model="data.addressLegal">
            </div>
            <div class="form-group">
              <input type="text" placeholder="Фактический адрес" v-model="data.addressActual">
            </div>
            <div class="flex-inputs">
              <div class="form-group">
                <input type="text" placeholder="БИК" v-model="data.bik" @keyup="getBankInfo">
                <transition name="fade">
                  <div class="search-result" v-if="showBank" style="top: 45px;">
                    <div class="result-list">
                      <template v-if="bank.length > 0">
                        <div class="list-label">Выберите банк</div>
                        <div class="result-list-item" v-for="(bank, index) in bank" :key="index"
                             @click="selectBank(bank)">
                          {{ bank.value }}
                        </div>
                      </template>
                      <template v-else>
                        <div class="list-label">Ничего не найдено</div>
                      </template>
                    </div>
                  </div>
                </transition>
              </div>
              <div class="form-group">
                <input type="text" placeholder="Расчётный счёт" v-model="data.paymentAccount">
              </div>
            </div>
            <div class="flex-inputs">
              <div class="form-group">
                <input type="text" placeholder="Корреспондентский счет" v-model="data.correspondent">
              </div>
              <div class="form-group">
                <input type="text" placeholder="Банк" v-model="data.bankName">
              </div>
            </div>
            <div class="offer">
              <div class="checkbox-custom">
                <input type="checkbox" checked>
                <p>Принимаю условия <span @click="openOffer">публичной оферты</span></p>
              </div>
            </div>
            <span class="politics">Нажимая “Отправить”, я соглашаюсь с
              <router-link to="/approval">Политикой конфиденциальности и Политикой в отношении обработки персональных данных.</router-link>
            </span>
            <button class="btn btn-info" type="submit">Отправить</button>
          </form>
        </div>
      </div>
      <div class="select-tutorial" v-else-if="engines.bnovo">
        <div class="engine-processing">
          <img src="@/assets/images/static-page/processing/processing.png" :style="style">
          <label>Страница находится в стадии разработки</label>
        </div>
      </div>
      <div class="select-tutorial" v-else-if="engines.alamat">
        <div class="another-hotel">
          <p>Поделитесь, каким движком бронирования вы пользуетесь, и расскажите немного о вашем отеле. Если мы получим
            этот движок, мы с вами свяжемся.</p>
          <form>
            <div class="form-group">
              <input type="text" placeholder="ИНН" required v-model="data.inn" @keyup="helpHint">
              <transition name="fade">
                <div class="search-result" v-if="showCompany" style="top: 45px;">
                  <div class="result-list">
                    <template v-if="hint.length > 0">
                      <div class="list-label">Выберите компанию</div>
                      <div class="result-list-item" v-for="(company, index) in hint" :key="index"
                           @click="selectCompany(company)">
                        {{ company.value }}
                      </div>
                    </template>
                    <template v-else>
                      <div class="list-label">Ничего не найдено</div>
                    </template>
                  </div>
                </div>
              </transition>
            </div>
            <div class="form-group">
              <input type="text" placeholder="Сайт (если есть)">
            </div>
            <div class="form-group">
              <input type="text" placeholder="Название отеля" required>
            </div>
            <div class="form-group">
              <input type="text" placeholder="Контактное лицо" required>
            </div>
            <div class="form-group">
              <input type="text" placeholder="Почта" required>
            </div>
            <div class="form-group">
              <masked-input type="text" class="masked" mask="\+\7-111-111-11-11" placeholder="Телефон" required/>
            </div>
            <div class="form-group">
              <input type="text" placeholder="Полное наименование" v-model="data.fullName" required>
            </div>
            <div class="form-group">
              <input type="text" placeholder="Юридический адрес" required v-model="data.addressLegal">
            </div>
            <div class="form-group">
              <input type="text" placeholder="Фактический адрес" required v-model="data.addressActual">
            </div>
            <div class="flex-inputs">
              <div class="form-group">
                <input type="text" placeholder="БИК" required v-model="data.bik" @keyup="getBankInfo">
                <transition name="fade">
                  <div class="search-result" v-if="showBank" style="top: 45px;">
                    <div class="result-list">
                      <template v-if="bank.length > 0">
                        <div class="list-label">Выберите банк</div>
                        <div class="result-list-item" v-for="(bank, index) in bank" :key="index"
                             @click="selectBank(bank)">
                          {{ bank.value }}
                        </div>
                      </template>
                      <template v-else>
                        <div class="list-label">Ничего не найдено</div>
                      </template>
                    </div>
                  </div>
                </transition>
              </div>
              <div class="form-group">
                <input type="text" placeholder="Расчётный счёт" v-model="data.correspondent" required>
              </div>
            </div>
            <div class="flex-inputs">
              <div class="form-group">
                <input type="text" placeholder="Корреспондентский счет" required>
              </div>
              <div class="form-group">
                <input type="text" placeholder="Банк" v-model="data.bankName" required>
              </div>
            </div>
            <div class="offer">
              <div class="checkbox-custom">
                <input type="checkbox" checked>
                <p>Принимаю условия <span @click="openOffer">публичной оферты</span></p>
              </div>
            </div>
            <span class="politics">Нажимая “Отправить”, я соглашаюсь с
              <router-link to="/approval">Политикой конфиденциальности и Политикой в отношении обработки персональных данных.</router-link>
            </span>
            <button class="btn btn-info" type="submit" @click="sendRequest">Отправить</button>
          </form>
        </div>
      </div>
      <div class="select-tutorial" v-else-if="engines.another">
        <div class="another-hotel">
          <p>Поделитесь, каким движком бронирования вы пользуетесь, и расскажите немного о вашем отеле. Если мы получим
            этот движок, мы с вами свяжемся.</p>
          <form>
            <div class="form-group">
              <input type="text" placeholder="ИНН" required v-model="data.inn" @keyup="helpHint">
              <transition name="fade">
                <div class="search-result" v-if="showCompany" style="top: 45px;">
                  <div class="result-list">
                    <template v-if="hint.length > 0">
                      <div class="list-label">Выберите компанию</div>
                      <div class="result-list-item" v-for="(company, index) in hint" :key="index"
                           @click="selectCompany(company)">
                        {{ company.value }}
                      </div>
                    </template>
                    <template v-else>
                      <div class="list-label">Ничего не найдено</div>
                    </template>
                  </div>
                </div>
              </transition>
            </div>
            <div class="form-group">
              <input type="text" placeholder="Сайт (если есть)">
            </div>
            <div class="form-group">
              <input type="text" placeholder="Название отеля" required>
            </div>
            <div class="form-group">
              <input type="text" placeholder="Контактное лицо" required>
            </div>
            <div class="form-group">
              <input type="text" placeholder="Почта" required>
            </div>
            <div class="form-group">
              <masked-input type="text" class="masked" mask="\+\7-111-111-11-11" placeholder="Телефон" required/>
            </div>
            <div class="form-group">
              <input type="text" placeholder="Полное наименование" v-model="data.fullName" required>
            </div>
            <div class="form-group">
              <input type="text" placeholder="Юридический адрес" required v-model="data.addressLegal">
            </div>
            <div class="form-group">
              <input type="text" placeholder="Фактический адрес" required v-model="data.addressActual">
            </div>
            <div class="flex-inputs">
              <div class="form-group">
                <input type="text" placeholder="БИК" required v-model="data.bik" @keyup="getBankInfo">
                <transition name="fade">
                  <div class="search-result" v-if="showBank" style="top: 45px;">
                    <div class="result-list">
                      <template v-if="bank.length > 0">
                        <div class="list-label">Выберите банк</div>
                        <div class="result-list-item" v-for="(bank, index) in bank" :key="index"
                             @click="selectBank(bank)">
                          {{ bank.value }}
                        </div>
                      </template>
                      <template v-else>
                        <div class="list-label">Ничего не найдено</div>
                      </template>
                    </div>
                  </div>
                </transition>
              </div>
              <div class="form-group">
                <input type="text" placeholder="Расчётный счёт" v-model="data.correspondent" required>
              </div>
            </div>
            <div class="flex-inputs">
              <div class="form-group">
                <input type="text" placeholder="Корреспондентский счет" required>
              </div>
              <div class="form-group">
                <input type="text" placeholder="Банк" v-model="data.bankName" required>
              </div>
            </div>
            <div class="offer">
              <div class="checkbox-custom">
                <input type="checkbox" checked>
                <p>Принимаю условия <span @click="openOffer">публичной оферты</span></p>
              </div>
            </div>
            <span class="politics">Нажимая “Отправить”, я соглашаюсь с
              <router-link to="/approval">Политикой конфиденциальности и Политикой в отношении обработки персональных данных.</router-link>
            </span>
            <button class="btn btn-info" type="submit" @click="sendRequest">Отправить</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import maskedInput from "vue-masked-input";
import * as EmailValidator from 'email-validator';

export default {
  name: 'connect-hotel',
  components: {maskedInput},
  data: function () {
    return {
      validate: {
        email: false,
      },
      data: {
        site: '',
        hotel: '',
        contact: '',
        phone: '',
        inn: '',
        fullName: '',
        addressLegal: '',
        addressActual: '',
        correspondent: '',
        paymentAccount: '',
        bankName: '',
        bik: '',
        email: '',
        type: 'travel',
      },
      hint: [],
      bank: [],
      engines: {
        travel: true,
        bnovo: false,
        alamat: false,
        another: false,
      },
      token: 'fcbda0e05e23d804ecc08e8fb878209f473b6fc3',
      timer: null,
      showCompany: false,
      showBank: false,
      turn: 0.1,
      style: '',
    }
  },
  async mounted() {
    this.rotation();
  },
  methods: {
    clearError: function () {
      if (EmailValidator.validate(this.data.email)) {
        this.validate.email = false;
      }
    },
    openOffer: function () {
      this.$root.$emit('openModal', 'offer');
    },
    selectCompany: function (company) {
      this.data.fullName = company.data.name.full_with_opf;
      this.data.inn = company.data.inn;
      this.data.addressLegal = company.data.address.value;
      this.showCompany = false;
    },
    selectBank: function (bank) {
      this.data.correspondent = bank.data.correspondent_account;
      this.data.bankName = bank.unrestricted_value;
      this.data.bik = bank.data.bic;
      this.showBank = false;
    },
    sendRequest: function () {
      this.$store.dispatch('sendConnectHotel', this.data).then((res) => {
        console.log(res)
        if(res.data.result === 'ok') {
          this.data.site = '';
          this.data.hotel = '';
          this.data.contact = '';
          this.data.phone = '';
          this.data.inn = '';
          this.data.fullName = '';
          this.data.addressLegal = '';
          this.data.addressActual = '';
          this.data.correspondent = '';
          this.data.paymentAccount = '';
          this.data.bankName = '';
          this.data.bik = '';
          this.data.email = '';
          this.$toast.success('Заявка успешно отправлена');
        }
      });
    },
    helpHint: function () {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        if (this.data.inn && this.data.inn.length >= 3) {
          let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party";
          let options = {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
              "Authorization": "Token " + this.token
            },
            body: JSON.stringify({query: this.data.inn})
          }

          fetch(url, options).then(async (res) => {
            let result = JSON.parse(await res.text());
            this.hint = result.suggestions;
            this.showCompany = true;
          });
        } else {
          this.showCompany = false;
        }
      }, 200);
    },
    getBankInfo: function () {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        if (this.data.bik && this.data.bik.length >= 3) {
          let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/bank";
          let options = {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
              "Authorization": "Token " + this.token
            },
            body: JSON.stringify({query: this.data.bik})
          }

          fetch(url, options).then(async (res) => {
            let result = JSON.parse(await res.text());
            this.bank = result.suggestions;
            this.showBank = true;
          });
        } else {
          this.showBank = false;
        }
      }, 200);
    },
    connectHotel: function () {
      this.$core.scrollToElement('connect-engine');
    },
    switchEngine: function (val) {
      let $this = this;
      this.engines[val] = true;
      this.data.type = val;
      Object.keys(this.engines).forEach(function (key) {
        if (key !== val) {
          $this.engines[key] = false;
        }
      });
    },
    rotation: function () {
      setTimeout(() => {
        this.turn += 0.1;
        this.style = "transform: rotate(" + this.turn + "turn)";
        this.rotation();
      }, 130);
    },
  }
}
</script>
